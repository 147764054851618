import { useState } from "react";
import AceEditor from "react-ace";
import { Button } from "@/components/ui/button";
import { useStickyState } from "@/lib/stickystate";
import { useFetch } from "@/lib/api";
import { toast } from "sonner";
import { Policy } from "@/types/policy";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

function PolicyEvaluator({ policy }: { policy: Policy | null }) {
  const [inputParams, setInputParams] = useStickyState(
    "",
    `policy-input-${policy?.id}`
  );
  const [evaluationResult, setEvaluationResult] = useState<string>("");
  const fetch = useFetch();

  const handleEvaluatePolicy = async () => {
    const evaluatePolicyPromise = fetch(`/policies/${policy?.id}/simulate`, {
      method: "PUT",
      body: inputParams ?? "",
    });

    toast.promise(evaluatePolicyPromise, {
      loading: "Evaluating policy...",
      success: "Policy evaluated",
      error: "Failed to evaluate policy",
    });

    const resp = await evaluatePolicyPromise;
    setEvaluationResult(JSON.stringify(resp, null, 2));
  };

  return (
    <div className="flex space-x-4">
      <div className="box-content w-1/2">
        <h3 className="font-semibold leading-none tracking-tight mb-4">
          Policy Input
        </h3>
        <AceEditor
          mode="json"
          value={inputParams}
          onChange={setInputParams}
          name="policy-editor"
          editorProps={{ $blockScrolling: true }}
          className="w-1/2"
          height="500px"
          width="100%"
        />
        <Button
          onClick={handleEvaluatePolicy}
          className="inline-block w-full mt-4"
          variant="default"
          disabled={!policy || !policy?.id}
        >
          Evaluate Policy
        </Button>
      </div>
      <div className="box-content w-1/2">
        <h3 className="font-semibold leading-none tracking-tight mb-4">
          Response
        </h3>
        <AceEditor
          mode="json"
          value={evaluationResult}
          name="policy-editor"
          editorProps={{ $blockScrolling: true }}
          className="w-1/2"
          height="500px"
          width="100%"
          readOnly
        />
      </div>
    </div>
  );
}

export default PolicyEvaluator;
