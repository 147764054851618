import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Switch } from "@/components/ui/switch";
import { Policy } from "@/types/policy";

interface PolicyListProps {
  policies: Policy[];
  onPolicySelect: (policy: Policy) => void;
  onPolicyStateChange: (id: string, isEnabled: boolean) => void;
  selectedPolicy: Policy | null;
}

function PolicyList({
  policies,
  onPolicySelect,
  onPolicyStateChange,
  selectedPolicy,
}: PolicyListProps) {
  const setChecked = (id?: string) => (isEnabled: boolean) => {
    if (!id) return;
    onPolicyStateChange(id, isEnabled);
  };

  return (
    <ScrollArea className="h-72 rounded-md border">
      <div className="p-4">
        <h4 className="mb-4 text-sm font-medium leading-none">Policies</h4>
        {policies.map((policy) => (
          <div key={policy.id}>
            <div
              className={`p-2 rounded-md cursor-pointer ${
                selectedPolicy && selectedPolicy.id === policy.id
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
              onClick={() => onPolicySelect(policy)}
            >
              {policy.name}
              <Switch
                className="float-right"
                checked={policy.is_enabled}
                disabled={!policy.id}
                onCheckedChange={setChecked(policy.id)}
              />
            </div>
            <Separator className="my-2" />
          </div>
        ))}
      </div>
    </ScrollArea>
  );
}

export default PolicyList;
