import { useState } from "react";
import AceEditor from "react-ace";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import useSWR, { useFetch } from "@/lib/api";
import EditableLabel from "@/components/editable-label";
import { toast } from "sonner";
import { Separator } from "@/components/ui/separator";
import PolicyEvaluator from "./PolicyEvaluator";
import PolicyList from "./PolicyList";
import { Policy } from "@/types/policy";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

const PolicySimulator = () => {
  const {
    data: policies,
    error,
    isLoading,
    mutate,
  } = useSWR<Policy[]>("/policies");
  const fetch = useFetch();
  const [editorValue, setEditorValue] = useState<Policy | null>(null);

  const handleSavePolicy = async () => {
    if (!editorValue) return;

    console.log("Saving policy", editorValue);
    const savePolicyPromise = editorValue.id
      ? fetch<Policy>(`/policies/${editorValue.id}`, {
          method: "PUT",
          body: JSON.stringify(editorValue),
        })
      : fetch<Policy>("/policies", {
          method: "POST",
          body: JSON.stringify({
            name: "New Policy",
            policy: editorValue.policy,
          }),
        });

    toast.promise(savePolicyPromise, {
      loading: "Saving policy...",
      success: "Policy saved",
      error: "Failed to save policy",
    });

    if (!editorValue.id) {
      const newPolicy = await savePolicyPromise;
      setEditorValue({ ...editorValue, id: newPolicy.id });
    }

    mutate();
  };

  const onPolicyStateChange = (id: string, isEnabled: boolean) => {
    const policy = policies?.find((policy) => policy.id === id);
    if (!policy) return;

    const updatedPolicies = policies?.map((p) =>
      p.id === id ? { ...p, is_enabled: isEnabled } : p
    );
    mutate(updatedPolicies, false);

    fetch(`/policies/${id}/enabled`, {
      method: "PUT",
      body: JSON.stringify({ enabled: isEnabled }),
    });
  };

  const handleLoadPolicy = (policy: Policy) => setEditorValue(policy);

  const setIntegration = (integration: string) => {
    if (!editorValue) return;
    setEditorValue({ ...editorValue, integration });
  };

  const saveEditorValue = (policy: string) => {
    if (!policy) return;
    setEditorValue({
      ...editorValue,
      policy,
      policy_config: editorValue?.policy_config ?? null,
      is_enabled: editorValue?.is_enabled ?? false,
      integration: editorValue?.integration ?? null,
    });
  };
  const savePolicyConfigEditorValue = (policyConfig: string) => {
    if (!policyConfig) return;
    setEditorValue({
      ...editorValue,
      policy_config: policyConfig,
      policy: editorValue?.policy ?? "",
      is_enabled: editorValue?.is_enabled ?? false,
      integration: editorValue?.integration ?? null,
    });
  };

  const handleCreatePolicy = () => {
    const newPolicy = {
      name: "New Policy",
      policy: "",
      is_enabled: false,
      integration: null,
      policy_config: null,
    };
    policies?.push(newPolicy);
    setEditorValue(newPolicy);
  };

  const renamePolicy = (name: string) => {
    if (!editorValue) return;
    setEditorValue({ ...editorValue, name });
    policies?.forEach((policy) => {
      if (policy.id === editorValue.id) policy.name = name;
    });
  };

  if (error) return <div>Error loading policies</div>;

  return (
    <div className="flex h-screen">
      <div className="w-1/4 p-4">
        <Card>
          <CardHeader>
            <CardTitle>Saved Policies</CardTitle>
          </CardHeader>
          <CardContent>
            {isLoading && <div>Loading...</div>}
            {policies && (
              <PolicyList
                policies={policies}
                onPolicySelect={handleLoadPolicy}
                selectedPolicy={editorValue}
                onPolicyStateChange={onPolicyStateChange}
              />
            )}
            <Button onClick={handleSavePolicy}>Save Policy</Button>
            <Button onClick={handleCreatePolicy} variant="outline">
              Create Policy
            </Button>
          </CardContent>
        </Card>
      </div>
      <div className="w-3/4 p-4">
        <Card>
          <CardHeader>
            <CardTitle>Policy Editor</CardTitle>
            <div className="flex items-center space-x-2">
              <EditableLabel
                initialValue={editorValue?.name || "New Policy"}
                className="inline-block w-2/3"
                onChange={renamePolicy}
              />
            </div>
            <div className="integration-settings">
              <h2 className="capitalize font-bold">Integration</h2>
              <EditableLabel
                initialValue={editorValue?.integration || "Null"}
                className="inline-block w-2/3"
                onChange={setIntegration}
              />
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex justify-between">
              <AceEditor
                mode="rego"
                theme="github"
                value={editorValue?.policy}
                onChange={saveEditorValue}
                name="policy-editor"
                editorProps={{ $blockScrolling: true }}
                className="mb-4 box-content w-1/2"
                showPrintMargin={false}
                height="400px"
                width="100%"
              />
              <AceEditor
                mode="json"
                theme="github"
                value={editorValue?.policy_config || ""}
                onChange={savePolicyConfigEditorValue}
                name="policy-config-editor"
                editorProps={{ $blockScrolling: true }}
                className="mb-4 box-content w-1/2"
                showPrintMargin={false}
                height="400px"
                width="100%"
              />
            </div>
            <Separator orientation="horizontal" className="my-4" />

            <PolicyEvaluator policy={editorValue} />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default PolicySimulator;
