import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import useSWR, { useFetch } from "@/lib/api";
import { toast } from "sonner";
import { CreateAccessRequestDialog } from "./create-access-request";
import { format } from "date-fns";

interface AccessRequest {
  id: string;
  target: string;
  requester_id: string;
  status: string;
  requested_at: string;
  approvedAt?: Date;
  deniedAt?: Date;
  expiresAt?: Date;
}

function StatusBadge({ status }: { status: string }) {
  const statusVariants = {
    pending: "Pending",
    approved: "Approved",
    denied: "Denied",
  };
  const variant =
    statusVariants[status as keyof typeof statusVariants] || "Unknown";
  return (
    <Badge
      variant={
        status === "approved"
          ? "green"
          : status === "denied"
          ? "destructive"
          : undefined
      }
    >
      {variant}
    </Badge>
  );
}

const AccessRequestsPage = () => {
  const fetch = useFetch();
  const { data, error, mutate } = useSWR<AccessRequest[]>("/accessrequests");

  if (error) return <div>Error loading access requests</div>;
  if (!data) return <div>Loading...</div>;

  const handleRequest = (id: string, action: "approve" | "deny") => {
    const promise = fetch(`/accessrequests/${id}/${action}`, { method: "PUT" });
    toast.promise(promise, {
      loading: `${
        action.charAt(0).toUpperCase() + action.slice(1)
      }ing request...`,
      success: `Request ${action}d`,
      error: `Failed to ${action} request`,
    });
    promise.then(() => mutate());
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mb-4 py-2">
        <h1>Access Requests</h1>
        <CreateAccessRequestDialog onSubmit={() => mutate()} />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Request ID</TableHead>
            <TableHead>Requester</TableHead>
            <TableHead>Resource</TableHead>
            <TableHead>Requested At</TableHead>
            <TableHead>Status</TableHead>
            <TableHead className="w-80">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((request) => (
            <TableRow key={request.id}>
              <TableCell>{request.id}</TableCell>
              <TableCell>{request.requester_id}</TableCell>
              <TableCell>{request.target}</TableCell>
              <TableCell>
                {format(new Date(request.requested_at), "d MMM, HH:mm:ss")}
              </TableCell>
              <TableCell>
                <StatusBadge status={request.status} />
              </TableCell>
              <TableCell className="flex justify-between">
                <Button
                  variant="green"
                  disabled={request.status !== "pending"}
                  className="w-1/2 mr-2"
                  onClick={() => handleRequest(request.id, "approve")}
                >
                  Approve
                </Button>
                <Button
                  variant="red"
                  disabled={request.status !== "pending"}
                  className="w-1/2 ml-2"
                  onClick={() => handleRequest(request.id, "deny")}
                >
                  Deny
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AccessRequestsPage;
